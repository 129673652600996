export default defineAppConfig({
  ui: {
    primary: 'ibh',
    gray: 'cool', // 'zinc' tailwind color
    table: {
      th: {
        base: 'relative text-center bg-secondary text-white border-none whitespace-pre min-w-[90px]',
        padding: 'px-3 py-3.5',
      },
      td: { base: 'whitespace-nowrap text-center' },
      tr: { base: 'divide-x' },
    },
  },
})
