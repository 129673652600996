import { type UserPermissions } from '~/composables'
import { createUserPermissions, getDefaultRoute } from '~/utils/routes'

import { type RouteLocationNormalized, type RouteRecordName } from '#vue-router'

export async function handleAuthChange(
  toRoute: RouteLocationNormalized,
  permissions: UserPermissions | undefined,
) {
  if (toRoute.matched.length === 0) return

  function safeNavigateTo(route: { path?: string; name?: string | RouteRecordName }) {
    if ((route.path && route.path !== toRoute.path) || (route.name && toRoute.name !== route.name))
      return navigateTo(route)
  }

  const meta = toRoute.meta
  if (meta.requirePermissions === 'unauthenticated' && !permissions) return
  // if no `unauthenticated` allowed, redirect to login
  else if (!permissions) {
    return navigateTo(getDefaultRoute())
  }

  const userPermissions = createUserPermissions(permissions)
  if (canAccessRoute(meta, userPermissions)) {
    // might be overloaded
    if (meta.metaName) {
      const overload = hasOverloadedRoute(meta.metaName, userPermissions, toRoute.name!)
      if (overload) {
        // currently wrong overloaded route
        console.warn("route overload - user shouldn't have been redirected here")
        return safeNavigateTo(overload)
      }
      // eslint-disable-next-line no-useless-return
    } else return
  }
  // redirected to dashboard
  else {
    return safeNavigateTo(getDefaultRoute(userPermissions))
  }
}

export default defineNuxtPlugin({
  enforce: 'post',
  setup: () => {
    addRouteMiddleware(
      'auth',
      (to) => {
        if (process.server) return

        const auth = useAuth()

        // ignore when loading
        if (auth.status === 'loading') return

        handleAuthChange(to, auth.ownPermissions)
      },
      {
        global: true,
      },
    )

    const auth = useAuth()
    // load store to register events
    const _ = useImpersonate()

    watch(
      [() => auth.ownPermissions, () => auth.status],
      ([newPermissions, status]) => {
        if (status === 'loading') return

        return handleAuthChange(useRoute(), newPermissions)
      },
      { deep: true, immediate: true },
    )
  },
})
