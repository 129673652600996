import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as documentsAtnLAchromMeta } from "/app/pages/documents.vue?macro=true";
import { default as employeesbB5UFJ3ERiMeta } from "/app/pages/employees.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as invoices7vnq8h5yeQMeta } from "/app/pages/invoices.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as patFfTtj37pHfMeta } from "/app/pages/pat.vue?macro=true";
import { default as permissions4WpBSRTWBOMeta } from "/app/pages/permissions.vue?macro=true";
import { default as powerRKpK1DmS6EMeta } from "/app/pages/power.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as tenantTzcZfMFFxpMeta } from "/app/pages/tenant.vue?macro=true";
import { default as ticketsLMEjejk3YqMeta } from "/app/pages/tickets.vue?macro=true";
import { default as trafficPSCASw69XjMeta } from "/app/pages/traffic.vue?macro=true";
import { default as usersY73XUGL82qMeta } from "/app/pages/users.vue?macro=true";
export default [
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact",
    path: contactV6oPJjs9RyMeta?.path ?? "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect || undefined,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard",
    path: dashboardJu37u7k0toMeta?.path ?? "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: documentsAtnLAchromMeta?.name ?? "documents",
    path: documentsAtnLAchromMeta?.path ?? "/documents",
    meta: documentsAtnLAchromMeta || {},
    alias: documentsAtnLAchromMeta?.alias || [],
    redirect: documentsAtnLAchromMeta?.redirect || undefined,
    component: () => import("/app/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: employeesbB5UFJ3ERiMeta?.name ?? "employees",
    path: employeesbB5UFJ3ERiMeta?.path ?? "/employees",
    meta: employeesbB5UFJ3ERiMeta || {},
    alias: employeesbB5UFJ3ERiMeta?.alias || [],
    redirect: employeesbB5UFJ3ERiMeta?.redirect || undefined,
    component: () => import("/app/pages/employees.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invoices7vnq8h5yeQMeta?.name ?? "invoices",
    path: invoices7vnq8h5yeQMeta?.path ?? "/invoices",
    meta: invoices7vnq8h5yeQMeta || {},
    alias: invoices7vnq8h5yeQMeta?.alias || [],
    redirect: invoices7vnq8h5yeQMeta?.redirect || undefined,
    component: () => import("/app/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: newsWdQv0BaSvYMeta?.name ?? "news",
    path: newsWdQv0BaSvYMeta?.path ?? "/news",
    meta: newsWdQv0BaSvYMeta || {},
    alias: newsWdQv0BaSvYMeta?.alias || [],
    redirect: newsWdQv0BaSvYMeta?.redirect || undefined,
    component: () => import("/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: patFfTtj37pHfMeta?.name ?? "pat",
    path: patFfTtj37pHfMeta?.path ?? "/pat",
    meta: patFfTtj37pHfMeta || {},
    alias: patFfTtj37pHfMeta?.alias || [],
    redirect: patFfTtj37pHfMeta?.redirect || undefined,
    component: () => import("/app/pages/pat.vue").then(m => m.default || m)
  },
  {
    name: permissions4WpBSRTWBOMeta?.name ?? "permissions",
    path: permissions4WpBSRTWBOMeta?.path ?? "/permissions",
    meta: permissions4WpBSRTWBOMeta || {},
    alias: permissions4WpBSRTWBOMeta?.alias || [],
    redirect: permissions4WpBSRTWBOMeta?.redirect || undefined,
    component: () => import("/app/pages/permissions.vue").then(m => m.default || m)
  },
  {
    name: powerRKpK1DmS6EMeta?.name ?? "power",
    path: powerRKpK1DmS6EMeta?.path ?? "/power",
    meta: powerRKpK1DmS6EMeta || {},
    alias: powerRKpK1DmS6EMeta?.alias || [],
    redirect: powerRKpK1DmS6EMeta?.redirect || undefined,
    component: () => import("/app/pages/power.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect || undefined,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: tenantTzcZfMFFxpMeta?.name ?? "tenant",
    path: tenantTzcZfMFFxpMeta?.path ?? "/tenant",
    meta: tenantTzcZfMFFxpMeta || {},
    alias: tenantTzcZfMFFxpMeta?.alias || [],
    redirect: tenantTzcZfMFFxpMeta?.redirect || undefined,
    component: () => import("/app/pages/tenant.vue").then(m => m.default || m)
  },
  {
    name: ticketsLMEjejk3YqMeta?.name ?? "tickets",
    path: ticketsLMEjejk3YqMeta?.path ?? "/tickets",
    meta: ticketsLMEjejk3YqMeta || {},
    alias: ticketsLMEjejk3YqMeta?.alias || [],
    redirect: ticketsLMEjejk3YqMeta?.redirect || undefined,
    component: () => import("/app/pages/tickets.vue").then(m => m.default || m)
  },
  {
    name: trafficPSCASw69XjMeta?.name ?? "traffic",
    path: trafficPSCASw69XjMeta?.path ?? "/traffic",
    meta: trafficPSCASw69XjMeta || {},
    alias: trafficPSCASw69XjMeta?.alias || [],
    redirect: trafficPSCASw69XjMeta?.redirect || undefined,
    component: () => import("/app/pages/traffic.vue").then(m => m.default || m)
  },
  {
    name: usersY73XUGL82qMeta?.name ?? "users",
    path: usersY73XUGL82qMeta?.path ?? "/users",
    meta: usersY73XUGL82qMeta || {},
    alias: usersY73XUGL82qMeta?.alias || [],
    redirect: usersY73XUGL82qMeta?.redirect || undefined,
    component: () => import("/app/pages/users.vue").then(m => m.default || m)
  }
]