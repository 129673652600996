import revive_payload_client_A5QOQdRTtZ from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a1GEjuspFi from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xztn6siYrB from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_Ub7jFNgAqV from "/app/modules/sentry/runtime/sentry.client.ts";
import payload_client_nTzfIdcEcX from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_r32M9E1iTg from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.2.2_vue@3.3.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_KiNwmOjKTK from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import colors_DDpBO4BMOL from "/app/node_modules/.pnpm/@nuxt+ui@2.11.0_jwt-decode@3.1.2_nuxt@3.8.1_ts-node@10.9.1_vite@4.5.0_vue@3.3.8/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_3dFO4wL2SD from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_HCHq2H8RJE from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_NkYDnyaRgR from "/app/node_modules/.pnpm/nuxt@3.8.1_@types+node@18.17.12_eslint@8.53.0_typescript@5.2.2_vite@4.5.0_vue-tsc@1.8.22/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import apexchart_gUgipXIQGn from "/app/plugins/apexchart.ts";
import marker_io_client_3vX6FuptAi from "/app/plugins/marker-io.client.ts";
import query_Ux2Qt90xcP from "/app/plugins/query.ts";
import vuedatepicker_oKNl9XPOyX from "/app/plugins/vuedatepicker.ts";
import auth_client_4lHQFpoKNF from "/app/plugins/auth.client.ts";
export default [
  revive_payload_client_A5QOQdRTtZ,
  unhead_a1GEjuspFi,
  router_xztn6siYrB,
  sentry_client_Ub7jFNgAqV,
  payload_client_nTzfIdcEcX,
  plugin_vue3_r32M9E1iTg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KiNwmOjKTK,
  colors_DDpBO4BMOL,
  plugin_client_3dFO4wL2SD,
  chunk_reload_client_HCHq2H8RJE,
  check_outdated_build_client_NkYDnyaRgR,
  apexchart_gUgipXIQGn,
  marker_io_client_3vX6FuptAi,
  query_Ux2Qt90xcP,
  vuedatepicker_oKNl9XPOyX,
  auth_client_4lHQFpoKNF
]