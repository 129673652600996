import { type InvalidateQueryFilters, type QueryKey, useMutation } from '@tanstack/vue-query'
import ky from 'ky'
import { type KyInstance } from 'ky/distribution/types/ky'
import { isFunction } from 'remeda'

let api: KyInstance | undefined

export function useApi() {
  return (
    api ??
    (api = ky.create({
      prefixUrl: `${useRuntimeConfig().public.apiBaseUrl}/rest`,
      retry: 0,
      timeout: 60000,
      hooks: {
        beforeRequest: [
          (request) => {
            request.headers.set('Authorization', `Bearer ${useAuth().accessToken}`)
          },
        ],
      },
    }))
  )
}

export function useOptimisticMutation<TVariables, TData>({
  mutationFn,
  optimisticUpdates,
  otherAffectedQueries = [],
}: {
  mutationFn: (variables: TVariables) => Promise<TData>
  optimisticUpdates: (variables: TVariables) => {
    queryKey: QueryKey
    updater: (previousState: TData | undefined) => TData
    clearCache?: boolean
    options?: Omit<InvalidateQueryFilters, 'queryKey'>
  }[]
  otherAffectedQueries?:
    | {
        queryKey: QueryKey
        clearCache?: boolean
        options?: Omit<InvalidateQueryFilters, 'queryKey'>
      }[]
    | ((variables: TVariables) => {
        queryKey: QueryKey
        clearCache?: boolean
        options?: Omit<InvalidateQueryFilters, 'queryKey'>
      }[])
}) {
  return useMutation({
    mutationFn,
    // optimistic updates
    onMutate: (variables: TVariables) => {
      const client = useQueryClientIfInitialized()
      if (!client) return

      const previousStates: {
        queryKey: QueryKey
        previousState: any
        clearCache?: boolean
        options?: Omit<InvalidateQueryFilters, 'queryKey'>
      }[] = []
      for (const { queryKey, updater, clearCache, options } of optimisticUpdates(variables)) {
        // snapshot previous state for rollback in case of api error
        const previousState = client.getQueryData<TData>(queryKey)

        // cancel any outgoing refetches so that they don't clobber the optimistic update when they resolve
        // ! none async -> allow timing of refreshed optimistic data updates
        client.cancelQueries({ queryKey }).then(() => {
          // optimistically update the query data; after cancel
          client.setQueryData(queryKey, updater(previousState))
        })

        // optimistically update the query data
        client.setQueryData(queryKey, updater(previousState))
        // snapshot previous state for rollback in case of api error
        previousStates.push({ queryKey, previousState, clearCache, options })
      }
      // expose snapshots to the context
      return { previousStates }
    },
    // rollback optimistic updates
    onError(_, __, context) {
      for (const { queryKey, previousState } of context?.previousStates ?? []) {
        useQueryClientIfInitialized()?.setQueryData(queryKey, previousState)
      }
    },
    // refetch queries after error or success
    onSettled: (_, __, variables, context) => {
      const client = useQueryClientIfInitialized()
      if (!client) return
      for (const { queryKey, clearCache, options } of context?.previousStates ?? []) {
        client.invalidateQueries({ queryKey, ...(options ?? {}) })
        if (clearCache) client.removeQueries({ queryKey })
      }
      const otherQueries = isFunction(otherAffectedQueries)
        ? otherAffectedQueries(variables)
        : otherAffectedQueries
      for (const { queryKey, clearCache, options } of otherQueries) {
        client.invalidateQueries({ queryKey, ...(options ?? {}) })
        if (clearCache) client.removeQueries({ queryKey })
      }
    },
    queryClient: useQueryClientIfInitialized(),
  })
}
