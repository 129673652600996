<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query'
import i18next from 'i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import translation from 'zod-i18n-map/locales/de/zod.json'

// Import your language translation files

import { setQueryClient } from './stores/query-client'

setQueryClient(useQueryClient())

// lng and resources key depend on your locale.
i18next.init({
  lng: 'de',
  resources: {
    de: { zod: translation },
  },
})
z.setErrorMap(zodI18nMap)
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UNotifications />
</template>
