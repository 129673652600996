import { promiseTimeout } from '@vueuse/core'
import jwtDecode from 'jwt-decode'

export function getTokenSecondsTillExpired([accessToken, refreshToken]: (string | null)[]) {
  if (!accessToken && !refreshToken) return undefined

  const mill = Math.min(
    accessToken ? jwtDecode<{ exp: number }>(accessToken)?.exp ?? 0 : 0,
    refreshToken ? jwtDecode<{ exp: number }>(refreshToken)?.exp ?? 0 : 0,
  )

  return mill - Date.now() / 1000
}

export async function scheduleRefresh(
  getSecondsTillExpired: () => number | undefined,
  action: () => void,
) {
  const secondsTillExpired = getSecondsTillExpired()

  // no tokens to refresh
  if (secondsTillExpired === undefined) return

  const remainingSeconds = 60
  const secondsTillRefresh = secondsTillExpired - remainingSeconds

  if (secondsTillRefresh > 0) await promiseTimeout(secondsTillRefresh * 1000)

  await action()
}
