import { storeToRefs } from 'pinia'
import { addProp, filter, isArray, map, mapValues, pipe, sortBy, toPairs, uniqBy } from 'remeda'

import { type UserPermissions } from '~/composables'

import { type RouteMeta, type RouteRecordName } from '#vue-router'

export type UserPermission = keyof UserPermissions
export type UserPermissionRequirement = UserPermission | `!${UserPermission}`

export function createUserPermissions(permissions: UserPermissions) {
  return pipe(
    permissions,
    mapValues((v) => !!v),
    toPairs,
    // has truthy value = has permission
    filter((pair) => pair[1]),
    map((pair) => pair[0] as keyof UserPermissions),
  )
}

// cache routes inside lifecycle
export const routes = computed(() => {
  const router = useRouter()
  const routes = pipe(
    router.getRoutes(),
    map((route) => ({ ...route, meta: route.meta as RouteMeta })),
    sortBy([(route) => route.meta.routePriority ?? -1, 'desc']),
  )
  return routes
})

function permissionGranted(
  permissions: UserPermission[],
  requirePermissions: UserPermissionRequirement[],
) {
  for (const reqPerm of requirePermissions) {
    if (reqPerm.startsWith('!')) {
      if (permissions.includes(reqPerm.substring(1) as UserPermission)) return false
    } else if (!permissions.includes(reqPerm as UserPermission)) return false
  }
  return true
}

export function canAccessRoute(meta: RouteMeta, permissions: UserPermission[]) {
  // not defined meta, need authentication
  if (!meta.requirePermissions) return true
  // logged in user should get redirected to index page
  else if (meta.requirePermissions === 'unauthenticated') return false

  // just has to be authenticated
  if (meta.requirePermissions.length === 0) return true

  for (const reqPermOption of meta.requirePermissions) {
    if (isArray(reqPermOption)) {
      if (permissionGranted(permissions, reqPermOption)) return true
    }
    // array of strings
    else
      return permissionGranted(permissions, meta.requirePermissions as UserPermissionRequirement[])
  }
  return false
}

/**
 * check if current route shouldn't be accessed, since there exists a route accessible overload
 */
export function hasOverloadedRoute(
  metaName: string,
  permissions: UserPermission[],
  currentRouteName: RouteRecordName,
) {
  // routes are sorted by overload
  for (const route of routes.value) {
    if (route.name === currentRouteName) return false

    if (route.meta.metaName === metaName && canAccessRoute(route.meta, permissions)) return route
  }
  return false
}

export function getOverloadedRoute(metaName: string, permissions: UserPermission[]) {
  // routes are sorted by overload
  for (const route of routes.value) {
    if (route.meta.metaName === metaName && canAccessRoute(route.meta, permissions)) return route
  }
  return routes.value.find((route) => route.meta.name === 'login')!
}

export function getDefaultRoute(permissions?: UserPermission[] | undefined) {
  if (!permissions) {
    // if no `unauthenticated` allowed, redirect to login
    return { name: 'index' }
  }

  return getOverloadedRoute('dashboard', permissions) ?? getOverloadedRoute('users', permissions)
}

export function useNavigationLinks() {
  const { status, ownPermissions, ownUser } = storeToRefs(useAuth())
  return computed(() => {
    if (status.value === 'loading' || !ownPermissions.value || !ownUser.value) return []

    const userPermissions = createUserPermissions(ownPermissions.value)

    return pipe(
      routes.value,
      filter(
        (route) => !route.meta.hideInNavigation && canAccessRoute(route.meta, userPermissions),
      ),
      // collapse overloaded routes
      uniqBy((route) => route.meta.metaName),
      sortBy([(route) => route.meta.layoutOrder ?? -1, 'desc']),
      // add nav user meta
      map((route) =>
        addProp(route, 'meta', {
          ...route.meta,
          permissions: ownPermissions.value,
          user: ownUser.value,
        }),
      ),
    )
  })
}
