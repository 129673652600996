import { defineStore } from 'pinia'

import { type User } from '~/composables'
import { retrieveImpersonate } from '~/composables/api/impersonate'

export const useImpersonate = defineStore('impersonate', () => {
  const toast = useToast()
  const auth = useAuth()

  const status = useLocalStorage<'active' | 'inactive'>('impersonateStatus', 'inactive')

  const adminAccessToken = useLocalStorage<string | null>('impersonateAccessToken', null, {
    listenToStorageChanges: false,
  }) as Ref<string | null>
  const adminRefreshToken = useLocalStorage<string | null>('impersonateRefreshToken', null, {
    listenToStorageChanges: false,
  }) as Ref<string | null>

  function clearTokens() {
    adminAccessToken.value = null
    adminRefreshToken.value = null
    status.value = 'inactive'
  }
  auth.logoutEvent.on(clearTokens)

  const adminClient = useApi().extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set('Authorization', `Bearer ${adminAccessToken.value}`)
        },
      ],
    },
  })

  async function beginImpersonate(userId: User['id']) {
    if (status.value === 'active') {
      return toast.add({
        color: 'red',
        title: 'Sie verkörpern bereits eine Person.',
      })
    }

    return retrieveImpersonate(userId)
      .then((result) => {
        // save admin tokens
        adminAccessToken.value = auth.accessToken ?? null
        adminRefreshToken.value = auth.refreshToken ?? null

        // set new user
        auth.setSession(result.access, result.refresh)

        status.value = 'active'
        toast.add({
          color: 'green',
          title: 'Sie verkörpern nun den Nutzer',
        })

        scheduleTokenRefresh()
      })
      .catch(() => {
        toast.add({
          color: 'red',
          title: 'Verkörperung fehlgeschlagen',
        })
      })
  }

  function endImpersonate() {
    if (status.value !== 'active') return

    // swap back
    auth.setSession(adminAccessToken.value!, adminRefreshToken.value!)

    // cancels refresh
    clearTokens()

    toast.add({
      color: 'green',
      title: 'Verkörperung beendet',
    })

    status.value = 'inactive'
  }

  function scheduleTokenRefresh() {
    scheduleRefresh(
      () => getTokenSecondsTillExpired([adminAccessToken.value, adminRefreshToken.value]),
      () => {
        // impersonation ended
        if (!adminRefreshToken.value) return

        refresh(adminRefreshToken.value, adminClient).then((result) => {
          adminAccessToken.value = result.access
          adminRefreshToken.value = result.refresh

          scheduleTokenRefresh()
        })
      },
    )
  }

  return {
    status,
    beginImpersonate,
    endImpersonate,
  }
})
