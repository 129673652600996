import { useQuery } from '@tanstack/vue-query'
import { type KyInstance } from 'ky/distribution/types/ky'

interface RefreshResponse {
  access: string
  refresh: string
}

/**
 * for: any user
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/login/login_refresh_create
 */
export function refresh(
  refreshToken: string,
  kyClient: KyInstance = useApi(),
): Promise<RefreshResponse> {
  return kyClient
    .post('login/refresh', {
      json: {
        refresh: refreshToken,
      },
    })
    .json<RefreshResponse>()
}

/**
 * for: only regular user (non-admin, non-manager)
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/login/login_pat_create_create
 */
export function getPAT() {
  return useApi().post('login/pat/create').json<{ token: string }>()
}

/**
 * for: only regular user (non-admin, non-manager)
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/login/login_pat_remove_destroy
 */
export function destroyPAT(): Promise<{ alreadyDestroyed: boolean }> {
  return useApi()
    .delete('login/pat/remove')
    .then(() => ({ alreadyDestroyed: false }))
    .catch(async (error) => {
      // only catch expected error
      const body = await error.response.json()
      if (body && body.detail === 'Nicht gefunden.') return { alreadyDestroyed: true }
      // re-throw unexpected error
      throw error
    })
}

export function useSelf() {
  return useQuery({
    queryKey: ['self'],
    queryFn: async () => {
      const api = useApi()
      const [user, permissions] = await Promise.all([
        api.get(`user/own`).json<User>(),
        api.get(`permission/own`).json<UserPermissions>(),
      ])
      return {
        user,
        permissions,
      }
    },
    refetchInterval: 5 * 60 * 1000,
    retry: false,
  })
}
