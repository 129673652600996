import { type User } from './user'

/**
 * requires: is_admin
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/login/login_impersonate_retrieve
 */
export function retrieveImpersonate(userId: User['id']) {
  return useApi().get(`login/impersonate/${userId}`).json<{
    access: string
    refresh: string
  }>()
}
