// idea of this: make useQueryClient accessible to all contexts
// solves: "Error: vue-query hooks can only be used inside setup() function"

import { type QueryClient } from '@tanstack/vue-query'

let queryClient: QueryClient | undefined
export function setQueryClient(initQueryClient: QueryClient) {
  queryClient = initQueryClient
}
export function useQueryClientIfInitialized() {
  return queryClient
}
