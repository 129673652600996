import { useQueryClient } from '@tanstack/vue-query'
import Cookies from 'js-cookie'
import { defineStore } from 'pinia'

export type SessionStatus = 'authenticated' | 'unauthenticated' | 'loading'

export type Session = ReturnType<typeof useAuth>

export const useAuth = defineStore('auth', () => {
  const logoutEvent = createEventHook<void>()

  const accessToken = useLocalStorage<string | null>(
    'accessToken',
    Cookies.get('jwt_access') ?? null,
    { listenToStorageChanges: false },
  ) as Ref<string | null>
  const refreshToken = useLocalStorage<string | null>(
    'refreshToken',
    Cookies.get('jwt_refresh') ?? null,
    { listenToStorageChanges: false },
  ) as Ref<string | null>

  const authState = ref<SessionStatus>(!refreshToken.value ? 'unauthenticated' : 'loading')

  // cookie cleanup in any case
  const cookieDomain = window.location.hostname
  Cookies.remove('jwt_access', { domain: cookieDomain })
  Cookies.remove('jwt_refresh', { domain: cookieDomain })

  const { data: self, isLoading: selfLoading, refetch: refetchSelf } = useSelf()

  const queryClient = useQueryClient()
  const logout = async () => {
    authState.value = 'unauthenticated'
    setSession(null, null)
    if (self.value) queryClient.setQueryData(['self'], null)
    logoutEvent.trigger()
  }

  function refreshSession() {
    if (!refreshToken.value) return

    return refresh(refreshToken.value)
      .then((result) => {
        accessToken.value = result.access
        refreshToken.value = result.refresh

        scheduleTokenRefresh()

        return result
      })
      .catch(() => {
        logout()
      })
  }

  function setSession(newAccessToken: string | null, newRefreshToken: string | null) {
    accessToken.value = newAccessToken
    refreshToken.value = newRefreshToken

    if (newAccessToken && newRefreshToken) {
      refetchSelf()
      scheduleTokenRefresh()
    }
  }

  function scheduleTokenRefresh() {
    scheduleRefresh(
      () => getTokenSecondsTillExpired([accessToken.value, refreshToken.value]),
      () => refreshSession(),
    )
  }
  scheduleTokenRefresh()

  watch(
    [selfLoading, self],
    ([isLoading, newSelf]) => {
      // initial / reload
      if (isLoading) {
        authState.value = 'loading'
      }
      // not authorized
      else if (!newSelf) return logout()
      else authState.value = 'authenticated'
    },
    {
      immediate: true,
    },
  )

  const ownUser = computed(() => self.value?.user)
  const ownPermissions = computed(() => self.value?.permissions)

  return {
    status: authState,
    ownUser,
    ownPermissions,
    refreshSession,
    setSession,
    accessToken,
    refreshToken,
    logout,
    logoutEvent,
  }
})
